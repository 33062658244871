/**
 * 只能 mixin 在最內層，因為考慮到有頁面權限判斷
 * 如果父子層都有 mixin，會導致通一頁但頁面權限判斷多次
 */
export const Permission = {
  computed: {
    // 權限表
    currentParticipantPermission() {
      return this.$store.state.StoreList.currentParticipantPermission
    },
  },
  watch: {
    // 權限表
    currentParticipantPermission: {
      handler(permission) {
        if (permission) {
          console.log('mixin permission')
          const routeName = this.$route.name
          let canNotGo = false
          console.log(routeName)
          if (
            routeName === 'Business' || routeName === 'Social' ||
            (!permission.accessSupplier && routeName === 'Suppliers') ||
            (!permission.updateStore && (routeName === 'VipsAndGroups' ||
              routeName === 'PersonalStore' || routeName === 'suppliers' ||
              routeName === 'Business' || routeName === 'Social' || routeName === 'ShippingPayments' || 
              routeName === 'PS_Merchandise' || routeName === 'PS_MerchOrder' || routeName === 'PS_Stock' ||
              routeName === 'PS_PurchaseOrder' || routeName === 'PS_Participant' || routeName === 'PS_Bill' ||
              routeName === 'PS_Bill' || routeName === 'PS_Shipper' || routeName === 'PS_CommonStyles' ||
              routeName === 'PS_Line'
            ))
          ) canNotGo = true
          
          if (canNotGo) {
            this.SweetAlert('other', '您尚未有權限進入')
            this.$router.back()
          }
        }
      },
      deep: true
    },
  },
}