<template>
  <div class="tw-container">
    <!-- 標題 -->
    <p class="tw-border-start fw-bolder tw-text-size20 m-lg-0 mt-3 ms-3">
      企業資訊
    </p>
    <hr />
    <div>
      <!-- 營業人名稱 -->
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="CompanyName"
          placeholder="營業人名稱(20字)"
          maxlength="20"
          v-model="companyName"
        />
        <label for="CompanyName">營業人名稱(20字)</label>
      </div>
      <!-- 統一編號 -->
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="BusinessAdminNumber"
          placeholder="統一編號(8字)"
          maxlength="8"
          v-model="businessAdminNumber"
        />
        <label for="BusinessAdminNumber">統一編號(8字)</label>
      </div>
      <!-- 公司負責人 -->
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="ResponsibleName"
          placeholder="公司負責人(10字)"
          maxlength="10"
          v-model="responsibleName"
        />
        <label for="ResponsibleName">公司負責人(10字)</label>
      </div>
      <!-- 公司地址 -->
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="CompanyLocation"
          placeholder="公司地址(50字)"
          maxlength="50"
          v-model="companyLocation"
        />
        <label for="CompanyLocation">公司地址(50字)</label>
      </div>
      <!-- 稅籍編號 -->
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="TaxSerialNumber"
          placeholder="稅籍編號(20字)"
          maxlength="20"
          v-model="taxSerialNumber"
        />
        <label for="TaxSerialNumber">稅籍編號(20字)</label>
      </div>

      <div class="text-end">
        <button class="tw-btn tw-btn-success" @click="recordBusinessInfo">
          儲存
        </button>
      </div>
    </div>
    <hr />
    <div></div>
  </div>
</template>

<script>
// mixin
import { Permission } from '@/methods/mixins/permission';

export default {
  data() {
    return {
      serverToken: '',
      storeId: 0,
      businessAdminNumber: '',
      companyName: '',
      responsibleName: '',
      companyLocation: '',
      taxSerialNumber: ''
    }
  },
  mixins: [Permission],
  created() {
    this.initialization()
    this.getStoreInfo()
  },
  watch: {
    businessAdminNumber() {
      this.businessAdminNumber = this.businessAdminNumber.replace(/\D/gm, '')
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 取得賣場資訊
    getStoreInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{}'
        },
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.businessAdminNumber = storeInfo.businessAdminNumber ? storeInfo.businessAdminNumber : ''
            vm.companyName = storeInfo.companyName ? storeInfo.companyName : ''
            vm.responsibleName = storeInfo.responsibleName ? storeInfo.responsibleName : ''
            vm.companyLocation = storeInfo.companyLocation ? storeInfo.companyLocation : ''
            vm.taxSerialNumber = storeInfo.taxSerialNumber ? storeInfo.taxSerialNumber : ''
            vm.$methods.switchLoading('hide')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 儲存企業資訊
    recordBusinessInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const updateIntroductionApi = `${process.env.VUE_APP_API}/store/setAttribute`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.storeId,
        attributes: {
          setBusinessAdminNumber: this.businessAdminNumber,
          setCompanyName: this.companyName,
          setResponsibleName: this.responsibleName,
          setCompanyLocation: this.companyLocation,
          setTaxSerialNumber: this.taxSerialNumber
        }
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateIntroductionApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })       
    },
  },
}
</script>